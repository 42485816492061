<template>
  <v-container>
    <v-data-table
      :headers="stationHeader"
      :items="itemsWithIndex"
      class="elevation-2"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title tag class="primary--text"
            >Station List</v-toolbar-title
          >
          <!-- max-width="950px" -->
          <v-dialog
          persistent
          v-model="editDialog"
          max-width="800px"
          > 
            <v-card>
              <!-- <v-toolbar
                dark
                color="primary"
              >
                <v-btn
                  icon
                  dark
                  @click="editDialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn> -->
                <!-- <v-toolbar-title>Settings</v-toolbar-title> -->
                <!-- <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn
                    dark
                    text
                    @click="dialog = false"
                  >
                    Save
                  </v-btn>
                </v-toolbar-items> -->
              <!-- </v-toolbar> -->
              <v-card-title id="topEditCard" >
                <span class="text-h5 primary--text">Edit Station {{stationIdEdited}}</span>
              </v-card-title>

              <v-card-text >
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-subheader class="pa-1 primary--text">
                        Station ID 
                      </v-subheader>
                      <v-text-field
                        v-model="editedStationData.stationId"
                        readonly
                        hint="This field cannot be edited"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-subheader class="pa-1 primary--text">
                        Location <span class="text-bold red--text ml-2">*</span>
                      </v-subheader>
                      <v-text-field
                        v-model="editedStationData.location"
                        dense
                        outlined
                        :rules="[v => !!v || 'Station location name is required']"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-subheader class="pa-1 primary--text">
                        Longitude <span class="text-bold red--text ml-2">*</span>
                      </v-subheader>
                      <v-text-field
                        v-model="editedStationData.longitud"
                        dense
                        outlined
                        :rules="[v => !!v || 'Station longitude is required']"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-subheader class="pa-1 primary--text">
                        Latitude <span class="text-bold red--text ml-2">*</span>
                      </v-subheader>
                      <v-text-field
                        v-model="editedStationData.latitude"
                        dense
                        outlined
                        :rules="[v => !!v || 'Station latitude is required']"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-subheader class="pa-1 primary--text">State</v-subheader>
                      <v-text-field
                        v-model="editedStationData.stateName"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-subheader class="pa-1 primary--text">Telecommunication Type</v-subheader>
                      <v-text-field
                        v-model="editedStationData.telecommunicationType"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-subheader class="pa-1 primary--text">Land Structure</v-subheader>
                      <v-text-field
                        v-model="editedStationData.landStructure"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-subheader class="pa-1 primary--text">Soil Type</v-subheader>
                      <v-text-field
                        v-model="editedStationData.soilType"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-subheader class="pa-1 primary--text">PIC Name</v-subheader>
                      <v-text-field
                        v-model="editedStationData.picName"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-subheader class="pa-1 primary--text">PIC Contact Number</v-subheader>
                      <v-text-field
                        v-model="editedStationData.picPhone"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-subheader class="pa-1 primary--text">
                        Time Alert Transmission <span class="text-bold red--text ml-2">*</span>
                      </v-subheader
                      >
                      <v-select
                        :items="listTime"
                        :item-text="'name'"
                        :item-value="'value'"
                        v-model="editedStationData.timeAlertTransmission  "
                        required
                        outlined
                        dense
                        :rules="[v => !!v || 'Time alert is required']"
                        :menu-props="{ bottom: true, offsetY: true }"
                        hide-details="auto"
                      ></v-select>
                    </v-col>

                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-title>
                <span class="text-h5 primary--text">Edit Picture</span>
              </v-card-title>

              <v-card-text>

                <v-card
                :loading="loadingUploadImage"
                elevation="4"
                >
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-subheader class="pa-1 primary--text">Station</v-subheader>
                        <a v-if="editedStationData.stationPicture != ''" :href="editedStationData.stationPicture" target="_blank">
                          <v-icon small class="ml-3 primary--text" style="text-decoration:none">mdi-message-image-outline</v-icon>
                          <span class="ml-2 primary--text" style="font-size:0.9em;">See Data Image</span>
                        </a>
                        <a v-if="editedStationData.stationPicture == ''" style="cursor:default;">
                          <v-icon small class="ml-3 black--text" style="cursor:default;">mdi-image-off</v-icon>
                          <span class="ml-2 black--text" style="font-size:0.9em;cursor:default;">No Data Image</span>
                        </a> 
                        <v-file-input
                          type="file"
                          :placeholder="
                            editedStationData.stationPicture.split('_').pop()
                          "
                          @change="
                            uploadPicture(uploadPic.file1, 'stationPicture')
                          "
                          v-model="uploadPic.file1"
                          clearable
                          dense
                          outlined
                          >test</v-file-input
                        >
                      </v-col>

                      <v-col cols="12" sm="6">
                        <v-subheader class="pa-1 primary--text">Deployment</v-subheader>
                        <a v-if="editedStationData.deploymentPicture != ''" :href="editedStationData.deploymentPicture" target="_blank">
                          <v-icon small class="ml-3 primary--text">mdi-message-image-outline</v-icon>
                          <span class="ml-2 primary--text" style="font-size:0.9em;">See Data Image</span>
                        </a>
                        <a v-if="editedStationData.deploymentPicture == ''" style="cursor:default;">
                          <v-icon small class="ml-3 black--text" style="cursor:default;">mdi-image-off</v-icon>
                          <span class="ml-2 black--text" style="font-size:0.9em;cursor:default;">No Data Image</span>
                        </a>
                        <v-file-input
                          type="file"
                          :placeholder="
                            editedStationData.deploymentPicture.split('_').pop()
                          "
                          @change="
                            uploadPicture(uploadPic.file2, 'deploymentPicture')
                          "
                          v-model="uploadPic.file2"
                          dense
                          outlined
                          >test</v-file-input
                        >
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-subheader class="pa-1 primary--text">Upstream</v-subheader>
                        <a v-if="editedStationData.upstreamPicture != ''" :href="editedStationData.upstreamPicture" target="_blank">
                          <v-icon small class="ml-3 primary--text">mdi-message-image-outline</v-icon>
                          <span class="ml-2 primary--text" style="font-size:0.9em;">See Data Image</span>
                        </a>
                        <a v-if="editedStationData.upstreamPicture == ''" style="cursor:default;">
                          <v-icon small class="ml-3 black--text" style="cursor:default;">mdi-image-off</v-icon>
                          <span class="ml-2 black--text" style="font-size:0.9em;cursor:default;">No Data Image</span>
                        </a> 
                        <v-file-input
                          type="file"
                          :placeholder="
                            editedStationData.upstreamPicture.split('_').pop()
                          "
                          @change="
                            uploadPicture(uploadPic.file3, 'upstreamPicture')
                          "
                          v-model="uploadPic.file3"
                          dense
                          outlined
                          >test</v-file-input
                        >
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-subheader class="pa-1 primary--text">Downstream</v-subheader>
                        <a v-if="editedStationData.downStreamPicture != ''" :href="editedStationData.downStreamPicture" target="_blank">
                          <v-icon small class="ml-3 primary--text">mdi-message-image-outline</v-icon>
                          <span class="ml-2 primary--text" style="font-size:0.9em;">See Data Image</span>
                        </a>
                        <a v-if="editedStationData.downStreamPicture == ''" style="cursor:default;">
                          <v-icon small class="ml-3 black--text" style="cursor:default;">mdi-image-off</v-icon>
                          <span class="ml-2 black--text" style="font-size:0.9em;cursor:default;">No Data Image</span>
                        </a>
                        <v-file-input
                          type="file"
                          :placeholder="
                            editedStationData.downStreamPicture.split('_').pop()
                          "
                          @change="
                            uploadPicture(uploadPic.file4, 'downStreamPicture')
                          "
                          v-model="uploadPic.file4"
                          dense
                          outlined
                          >test</v-file-input
                        > 
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
                
              </v-card-text>


              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="postEditStation">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog persistent v-model="dialogDelete" max-width="550px">
            <v-card>
              <v-card-title class="error white--text">
                Delete Station <v-icon style="color:white;margin-left:15px;">mdi-delete-empty</v-icon>
              </v-card-title>
              <v-card-text style="padding:20px 24px;font-weight:bold;">
                Are you sure you want to delete this station? ({{delSelected}})
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="success--text" text @click="close">Cancel</v-btn>
                <v-btn class="error--text" text @click="postDeleteStation">YES</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:[`item.no`]="props">
        {{ props.item.index }}
      </template>
      <template v-slot:[`item.actions`]="props">
        <template>
          <v-icon
            small
            class="mr-2 green--text"
            @click="editStationItem(props.item); scrollTo();"
            >mdi-pencil</v-icon
          >
          <v-icon
            small
            class="red--text"
            text
            @click="deleteStation(props.item.stationId)"
            >mdi-delete</v-icon
          >
        </template>
      </template>
    </v-data-table>


    <!-- Popups/Dialogs Success Param Added-->
    <div class="text-center">
        <v-dialog
        v-model="dialogSuccessStationEdited"
        width="500"
        >
        <v-card>
            <v-card-title class="success white--text">
            Success! <v-icon style="color:white;margin-left:15px;">mdi-check-circle</v-icon>
            </v-card-title>

            <v-card-text style="padding:20px 24px;font-weight:bold;">
            Station successfully edited.
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                class="success--text"
                text
                @click="dialogSuccessStationEdited = false; editDialog = false; reloadPage()"
            >
                OK
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </div>

    <!-- Popups/Dialogs Error Add Station-->
    <div class="text-center">
        <v-dialog
        v-model="dialogErrorEditStation"
        width="500"
        >
        <v-card>
            <v-card-title class="error white--text">
            Error! <v-icon style="color:white;margin-left:15px;">mdi-alert-circle</v-icon>
            </v-card-title>

            <v-card-text style="padding:20px 24px;font-weight:bold;">
            Something went wrong. Please make sure all required field is filled and try again.
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                class="error--text"
                text
                @click="dialogErrorEditStation = false"
            >
                OK
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </div>


  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({

    stationIdEdited: null,

    dialogSuccessStationEdited: false,
    dialogErrorEditStation: false,

    listTime: [
      {name: "15 Minutes", value: "00:15:00"},
      {name: "30 Minutes", value: "00:30:00"},
      {name: "1 Hour", value: "00:00:00"},
    ],
    loadingUploadImage: false,
    urlPic1: null,
    uploadPic: {
      file1: null,
      file2: null,
      file3: null,
      file4: null,
    },
    delSelected: "",
    editDialog: false,
    dialogDelete: false,
    stationHeader: [
      { text: "No.", align: "start", value: "no" },
      { text: "Station ID", value: "stationId" },
      { text: "Location", value: "location" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    stationData: [],
    editedStationData: {
      stationId: "",
      location: "",
      longitud: "",
      latitude: "",
      stateName: "",
      telecommunicationType: "",
      landStructure: "",
      soilType: "",
      picName: "",
      picPhone: "",
      timeAlertTransmission  : "",
      stationPicture: "",
      deploymentPicture: "",
      upstreamPicture: "",
      downStreamPicture: "",
      // DO_CON_ACTIVE: null,
      // DO_SAT_ACTIVE: null,
      // TEMPERATURE_ACTIVE: null,
      // BOD_ACTIVE: null,
      // COD_ACTIVE: null,
      // TSS_ACTIVE: null,
      // TOC_ACTIVE: null,
      // pH_ACTIVE: null,
      // NH3N_ACTIVE: null,
      // NH4_ACTIVE: null,
      // SALINITY_ACTIVE: null,
      // TURBIDITY_ACTIVE: null,
      // CONDUCTIVITY_ACTIVE: null,
      // CHLOROPHYLL_A_ACTIVE: null,
    },

    filterstationData: [],
    paramArray: [],
    paramItems: {
      DO_CON_ACTIVE: "",
      "4_4_DDT_ACTIVE": "",
      AG_ACTIVE: "",
      AL_ACTIVE: "",
      ALDRIN_DIELDRIN_ACTIVE: "",
      AS_ACTIVE: "",
      B_ACTIVE: "",
      BA_ACTIVE: "",
      BHC_ACTIVE: "",
      BOD_ACTIVE: "",
      BR_ACTIVE: "",
      CA_ACTIVE: "",
      CCE_ACTIVE: "",
      CD_ACTIVE: "",
      CDOM_ACTIVE: "",
      CHLORDANE_ACTIVE: "",
      CHLOROPHYLL_A_ACTIVE: "",
      CL_ACTIVE: "",
      CL2_ACTIVE: "",
      CN_ACTIVE: "",
      CO2_ACTIVE: "",
      COD_ACTIVE: "",
      COLOUR_ACTIVE: "",
      CONDUCTIVITY_ACTIVE: "",
      CR_ACTIVE: "",
      CR_III_ACTIVE: "",
      CR_IV_ACTIVE: "",
      CU_ACTIVE: "",
      DEPTH_ACTIVE: "",
      DO_SAT_ACTIVE: "",
      DOC_ACTIVE: "",
      E_COLI_ACTIVE: "",
      EC_ACTIVE: "",
      ENDOSULFAN_ACTIVE: "",
      F_ACTIVE: "",
      FC_ACTIVE: "",
      FDOM_ACTIVE: "",
      FE_ACTIVE: "",
      FLOWRATE_ACTIVE: "",
      GROSS_ALPHA_ACTIVE: "",
      GROSS_BETA_ACTIVE: "",
      HARDNESS_ACTIVE: "",
      HEDONAL_2_4_D_ACTIVE: "",
      HEPTACHLOR_EPOXIDE_ACTIVE: "",
      HG_ACTIVE: "",
      K_ACTIVE: "",
      LINDANE_ACTIVE: "",
      MBAS_ACTIVE: "",
      MG_ACTIVE: "",
      MN_ACTIVE: "",
      NA_ACTIVE: "",
      NH3N_ACTIVE: "",
      NH4_ACTIVE: "",
      NI_ACTIVE: "",
      NO2_ACTIVE: "",
      NO3_ACTIVE: "",
      OG_EDIBLE_ACTIVE: "",
      OG_MINERAL_ACTIVE: "",
      P_ACTIVE: "",
      PARAQUAT_ACTIVE: "",
      PB_ACTIVE: "",
      PCB_ACTIVE: "",
      pH_ACTIVE: "",
      PHENOL_ACTIVE: "",
      PO4_3_ACTIVE: "",
      RA226_ACTIVE: "",
      S_ACTIVE: "",
      SALINITY_ACTIVE: "",
      SE_ACTIVE: "",
      SN_ACTIVE: "",
      SO4_ACTIVE: "",
      SOLAR_BATTERY_ACTIVE: "",
      SILVEX_2_4_5_TP_ACTIV: "",
      SR_90_ACTIVE: "",
      TC_ACTIVE: "",
      TDS_ACTIVE: "",
      TEMPERATURE_ACTIVE: "",
      TOC_ACTIVE: "",
      TRIOXONE_2_4_5_T_ACTIVE: "",
      TSS_ACTIVE: "",
      TURBIDITY_ACTIVE: "",
      U_ACTIVE: "",
      VELOCITY_ACTIVE: "",
      ZN_ACTIVE: "",
      TRYPHPTOPHAN_ACTIVE: ""
    },

    editedIndex: -1,

    // Scroll Control
    // type: 'element',
    // selected: 'dialog',
  }),

  methods: {

    uploadPicture(data, fileKey) {

      this.loadingUploadImage = true;

      let formData = new FormData();

      formData.append("files", data);

      axios
        .post(`${process.env.VUE_APP_API_URL}/cms/upload`, formData, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          // this.stationData = response.data.filepath
          this.editedStationData[fileKey] = response.data.filepath;
          // console.log(this.editedStationData[fileKey].split('_').pop())
          // console.log(this.editedStationData[fileKey])

          console.log(response);
          this.loadingUploadImage = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingUploadImage = false;
        });
    },

    getAllStation() {
      axios
        .get(`${process.env.VUE_APP_API_URL}/cms/stations`, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          this.stationData = response.data;
          console.log(this.stationData);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getAllParamActive(item){

      this.filterstationData = [];

      let keyDataItem = Object.keys(item);

      axios
      .get(`${process.env.VUE_APP_API_URL}/cms/stations`, {
        headers: {
          Authorization: "Bearer " + this.$store.getters.user.token,
        },
      })
      .then((response) => {
        // console.log(response.data);

        let keyData = Object.keys(response.data[0]);
        // console.log(keyData);

        for (let i = 0; i < keyData.length; i++) {
          if (keyData[i].includes("ACTIVE") == true) {
              this.filterstationData.push(keyData[i]);
          }
        }

        for (let i = 0; i < this.filterstationData.length; i++) {
          for (let x = 0; x < keyDataItem.length; x++) {
            if (this.filterstationData[i] == keyDataItem[x]) {
              this.paramItems[this.filterstationData[i]] = item[keyDataItem[x]];
            }
          }
        }

        console.log(this.paramItems);

      })
      .catch((error) => {
        console.log(error);
      });
      

    },

    checkTest(){
      // console.log(this.paramArray);
      // console.log(this.editedStationData);
    },

    postEditStation() {

      // let succeedEdit = null;
      // console.log(succeedEdit);

      // Post Edit Station Details
      // let object = this.paramArray.reduce(
      //   (obj, item) => Object.assign(obj, { [item.name]: item.value=="null" ? null : item.value }),
      //   {}
      // );

      // const payload = { ...this.editedStationData, ...object };

      // const payload = this.editedStationData;
      // console.log(this.editedStationData);

      if (this.editedStationData.location != "" && this.editedStationData.longitud != ""
      && this.editedStationData.latitude != "") {
      // && this.editedStationData.latitude != "" && this.editedStationData.timeAlertTransmission != "") {

        let payload = JSON.stringify(this.editedStationData);

        console.log(payload);

        axios.put(`${process.env.VUE_APP_API_URL}/cms/editstation`, payload, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          // this.getAllStation();
          console.log(response.data);
          // this.close();
          // succeedEdit = "success";
          this.dialogSuccessStationEdited = true;
        })
        .catch((error) => {
          console.log(error);
          // succeedEdit = "failed";
          this.dialogSuccessStationEdited = false;
          this.dialogErrorEditStation = true;
        });
        
      }
      else{
        this.dialogErrorEditStation = true;
      }

      


      // let myInterval = setInterval(() => {
      //     if (succeedEdit == "success") {
      //         this.dialogSuccessStationEdited = true;
      //     }
      //     else if(succeedEdit == "failed"){
      //         this.dialogErrorEditStation = true;
      //     }
      //     clearInterval(myInterval);
      // }, 1000);

      
    },

    postDeleteStation() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/cms/delstation?stationid=${this.delSelected}`,
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          }
        )
        .then((response) => {
          let temp = this.stationData.findIndex(
            (item) => item.stationId === this.delSelected
          );
          this.stationData.splice(temp, 1);
          this.close();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    editStationItem(item) {
      console.log(item);

      
      this.editedIndex = this.stationData.indexOf(item);
      this.editedStationData = Object.assign({}, item);
      this.editDialog = true;
      this.getAllParamActive(item)

      this.stationIdEdited = item.stationId;

      // console.log(this.editedStationData);
      this.editedStationData.timeAlertTransmission = item.TIME_ALERT_TRANSMISSION;
      // console.log(this.editedStationData);
    },

    scrollTo(){
      // document.getElementById("topEditCard").scrollIntoView()
    },

    deleteStation(val) {
      console.log(val);
      this.delSelected = val;
      this.dialogDelete = true;
    },

    close() {
      this.editDialog = false;
      this.dialogDelete = false;
      this.editedStationData.stationPicture = "";
      this.editedStationData.deploymentPicture = "";
      this.editedStationData.upstreamPicture = "";
      this.editedStationData.downStreamPicture = "";
      this.stationIdEdited = null;
    },
  },

  computed: {
    itemsWithIndex() {
      return this.stationData.map((stationData, index) => ({
        ...stationData,
        index: index + 1,
      }));
    }
  },

  created() {
    for (let key in this.paramItems) {
      this.paramArray.push({ name: key, param: key.split("_ACTIVE")[0], value: null, min: null, max: null, outlierMin: null, outlierMax: null });
    }
  },

  mounted() {
    this.getAllStation();
    // this.getAllParamActive();
    this.editedStationData.downStreamPicture;
  },
};
</script>

<style lang="scss" scoped>
</style>